import { FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';
import layout from '@/constants/layout';

import Heading from '../Typography/Heading';
import Quote from '../Testimonials/Quote';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

interface TempleBase {
  topImageSrc?: string;
  topImageAlt?: string;
  topSectionTitle?: string;
  topSectionText?: ReactNode;
  bottomImageSrc?: string;
  bottomImageAlt?: string;
  bottomImageTitle?: string;
  bottomSectionText?: ReactNode;
  testimonial?: string;
  topSectionLeft?: ReactNode;
  topSectionRight?: ReactNode;
  hasQuote?: Boolean;
}

interface TempleOne extends TempleBase {}
interface TempleTwo extends TempleBase {}
interface TempleThree extends TempleBase {}
interface BlogProps extends React.HTMLAttributes<HTMLDivElement> {
  templeOne?: TempleOne;
  templeTwo?: TempleTwo;
  templeThree?: TempleThree;
}

const BlogTemple: FC<BlogProps> = ({ templeOne, templeTwo, templeThree }) => {
  return (
    <>
      {templeOne ? (
        <>
          <div {...{ css: contentWrapper }}>
            <img
              {...{
                src: templeOne.topImageSrc,
                alt: templeOne.topImageAlt,
                css: image,
              }}
            />
            <Heading
              {...{
                style: { color: '#315EFF' },
                variant: 'h3',
                children: templeOne.topSectionTitle,
              }}
            />
          </div>
          <p {...{ css: topParagraphStyles }}>{templeOne.topSectionText}</p>
          <div {...{ css: bottomContentContainer }}>
            {templeOne?.hasQuote ? (
              <>
                <div>
                  <div {...{ css: imageOverlayContainer }}>
                    <div {...{ css: overlayBackground }}></div>
                    <div {...{ css: imageContainer }}>
                      <img
                        src={templeOne.bottomImageSrc}
                        alt={templeOne.bottomImageAlt}
                      />
                    </div>
                  </div>{' '}
                </div>
                <div {...{ css: bottomParagraphContainer }}>
                  <Heading
                    {...{
                      style: { color: '#315EFF', margin: '0' },
                      variant: 'h3',
                      children: templeOne.bottomImageTitle,
                    }}
                  />
                  <p {...{ css: textStyles }}>{templeOne.bottomSectionText}</p>
                  <Quote
                    {...{ templeName: templeOne?.testimonial, templeOne: true }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : null}

      {templeTwo ? (
        <>
          <div {...{ css: topSectionContainer }}>
            <div {...{ css: contentColumn }}>
              <Heading
                {...{
                  style: { color: '#315EFF', margin: '0' },
                  variant: 'h3',
                  children: templeTwo.topSectionTitle,
                }}
              />
              <p {...{ css: textStyles }}>{templeTwo.topSectionText}</p>
            </div>
            <div {...{ css: contentColumn }}>
              <img src={templeTwo.topImageSrc} alt={templeTwo.topImageAlt} />
            </div>
          </div>
          <div {...{ css: contentWrapper, style: { marginTop: '2em' } }}>
            <Heading
              {...{
                style: { color: '#315EFF' },
                variant: 'h3',
                children: templeTwo.bottomImageTitle,
              }}
            />
            <p {...{ css: textStyles }}>{templeTwo.bottomSectionText}</p>
            <Quote {...{ templeName: templeTwo?.testimonial }} />
            <img
              {...{
                src: templeTwo.bottomImageSrc,
                alt: templeTwo.bottomImageAlt,
                style: { width: '100%' },
              }}
            />
          </div>
        </>
      ) : null}

      {templeThree ? (
        <div {...{ css: contentWrapper }}>
          <Heading
            {...{
              style: {
                color: '#315EFF',
                margin: '0',
                alignSelf: 'center',
                fontSize: '40px',
              },
              variant: 'h2',
              children: templeThree.topSectionTitle,
            }}
          />

          <div {...{ css: containerColumn }}>
            <div {...{ css: contentColumn }}>
              <p {...{ css: textStyles }}>{templeThree.topSectionLeft}</p>
            </div>
            <div {...{ css: contentColumn }}>
              <p {...{ css: textStyles }}>{templeThree.topSectionRight}</p>
            </div>
          </div>
          <Quote
            {...{ styleNone: true, templeName: templeThree?.testimonial }}
          />
          <img
            {...{
              src: templeThree.bottomImageSrc,
              alt: templeThree.bottomImageAlt,
              style: { width: '100%' },
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default memo(BlogTemple);

const topParagraphStyles = css`
  padding: 0 20%;
  font-family: Inter;
  @media (max-width: ${mobile}px) {
    padding: 0;
  }
`;

const bottomContentContainer = css`
  display: flex;
  justify-content: center;
  gap: 80px;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    margin: 0 auto;
    gap: 0;
  }
`;

const bottomParagraphContainer = css`
  width: 600px;
  @media (max-width: ${mobile}px) {
    width: 350px;
  }
`;

const imageOverlayContainer = css`
  width: 450px;
  height: 450px;
  position: relative;
  margin: 20px;
  @media (max-width: ${mobile}px) {
    width: 300px;
    height: 300px;
  }
`;

const overlayBackground = css`
  width: 60%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #cdcdd1;
  @media (max-width: ${mobile}px) {
    left: -15px;
  }
`;

const imageContainer = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 9;
  margin: 25px 0px 0px 40px;
  @media (max-width: ${mobile}px) {
    top: 30px;
    left: 10px;
    margin: auto;
  }
`;

const image = css`
  height: 600px;
  max-width: 100%;
`;

//TEMPLE TWO
const topSectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 0 20%;
  gap: 2em;
  @media (max-width: ${mobile}px) {
    flex-direction: column-reverse;
    padding: 0;
    margin: auto;
    gap: 1em;
  }
`;

const containerColumn = css`
  display: flex;
  gap: 3em;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    gap: 1em;
  }
`;

const contentColumn = css`
  width: 50%;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const textStyles = css`
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
`;
const contentWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 0 20%;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 0;
    gap: 1em;
  }
`;
