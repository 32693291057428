import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Bottom from '../../assets/images/tenens-agency-bottom.jpeg';
import Top from '../../assets/images/tenens-agency-top.jpeg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Tips Locum Tenens </title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeOne: {
              topImageSrc: Top,
              topImageAlt: 'A doctors sitting at a conference table',
              topSectionTitle: 'Tips For Choosing A Locum Tenens Agency',
              topSectionText: (
                <>
                  Are you a physician, nurse, advanced practitioner, or other
                  healthcare specialist who is interested in working locum
                  tenens? If so, you’ll be joining the 52,000+ (and counting!)
                  physicians and practitioners who earn their living as locum
                  tenens providers. And for good reason. Locum tenens offers
                  flexibility, travel, experience, and so much more.
                  <br />
                  <br />
                  <b>There’s no shortage of locum tenens jobs.</b> According to
                  RevCycleIntelligence, 88% of healthcare organizations reported
                  that they employ locum tenens providers to fill staffing gaps.
                  This percentage continues to rise. Broken down by specialty,
                  anesthesia providers were the most utilized type of locum
                  professional at 28%, and hospitalists were next at 25%. Nearly
                  23% of healthcare facilities used temporary behavioral health
                  providers, and next on the list were family medicine, internal
                  medicine, and pediatricians.
                  <br />
                  <br />
                  In short,{' '}
                  <b>
                    hospitals are hiring locum tenens, and the trend continues
                    to rise. If you’re interested,
                  </b>{' '}
                  there’s plenty of work to be found. Because our country has a
                  physician shortage and facilities are using locum tenens
                  practitioners to fill gaps, there are countless opportunities
                  available to qualified candidates who want to work.
                </>
              ),
              hasQuote: true,
              bottomImageSrc: Bottom,
              bottomImageAlt: 'Recruiter typing data',
              bottomImageTitle: 'How to choose a locum tenens agency',
              bottomSectionText: (
                <>
                  When you work with an agency, you’ll want to consider these
                  important considerations:
                  <ol {...{ css: orderList }}>
                    <li>
                      <b>Experience and focus areas.</b> Find out what
                      geographic regions the agency represents and whether or
                      not the agency specializes in your discipline. You’ll want
                      to work with recruiters who not only deeply understand
                      your specialty but who can get to know you, your personal
                      goals, and your career aspirations. You’ll find that some
                      agencies are very narrow in focus, be that in specialty or
                      geography. Other agencies are more general, and still
                      others have teams of experts who focus on certain
                      specialties and regions and can provide a wider array of
                      opportunities.
                    </li>
                    <li>
                      <b>Customer service.</b> You’ll be communicating a lot
                      with your agency representatives. A good agency will find
                      you the job, help you with your paperwork, and negotiate
                      for you, plus handle your travel arrangements and other
                      logistics. You want someone in your corner who understands
                      your needs and goals and is reliable and professional.
                    </li>
                    <li>
                      <b>Check the agency’s job listings.</b> A good agency will
                      give you access to a plethora of opportunities, and they
                      will also bring those opportunities to you. Are there
                      locations that interest you? Specific assignments or in
                      parts of the country?
                    </li>
                    <li>
                      <b>
                        Ask about licensing, credentialing, and malpractice
                        insurance,
                      </b>{' '}
                      and choose an agency that offers and provides all three.
                      You want an agency that can help you every step of the way
                      and covers you with top-notch insurance protection. Plus,
                      working with an agency that understands the intricacies of
                      different state regulations can make your experience a
                      smooth one.
                    </li>
                    <li>
                      <b>Ask about pay and benefits,</b> and choose an agency
                      that helps you get what you deserve. A solid agency will
                      negotiates your rates, your preferred shifts, days off,
                      and per diem allowances on your behalf. Make sure to ask
                      up front when and how you will get paid.
                    </li>
                  </ol>
                  Are you ready to work locum tenens? Join the thousands who
                  have made locum tenens their lifestyles.
                </>
              ),
              testimonial:
                "I've been incredibly impressed with Locums.com. As a locum tenens physician, finding the right assignment can be challenging. This job board helps me quickly search for opportunities that match my specialty and preferences. I am able to find a wide range of high-quality assignments and connect with the right recruiters.",
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
